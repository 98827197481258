.circliful {
    position: relative;
}

.circle-text, .circle-info, .circle-text-half, .circle-info-half {
    width: 100%;
    position: absolute;
    text-align: center;
    display: inline-block;
}

.circle-info, .circle-info-half {
    color: #999;
}

.circliful .fa {
    margin: -10px 3px 0 3px;
    position: relative;
    bottom: 4px;
}