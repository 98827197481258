/** GENERAL **/

@font-face
{
    font-family : 'Roboto';
    src         : url('../fonts/Roboto-Regular-webfont.eot');
    src         : url('../fonts/Roboto-Regular-webfont.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Roboto-Regular-webfont.woff') format('woff'),
    url('../fonts/Roboto-Regular-webfont.ttf') format('truetype'),
    url('../fonts/Roboto-Regular-webfont.svg#robotoregular') format('svg');
    font-weight : normal;
    font-style  : normal;
}

@font-face
{
    font-family : 'Roboto';
    src         : url('../fonts/Roboto-Light-webfont.eot');
    src         : url('../fonts/Roboto-Light-webfont.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Roboto-Light-webfont.woff') format('woff'),
    url('../fonts/Roboto-Light-webfont.ttf') format('truetype'),
    url('../fonts/Roboto-Light-webfont.svg#robotolight') format('svg');
    font-weight : 300;
    font-style  : normal;
}

@font-face
{
    font-family : 'Roboto';
    src         : url('../fonts/Roboto-LightItalic-webfont.eot');
    src         : url('../fonts/Roboto-LightItalic-webfont.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Roboto-LightItalic-webfont.woff') format('woff'),
    url('../fonts/Roboto-LightItalic-webfont.ttf') format('truetype'),
    url('../fonts/Roboto-LightItalic-webfont.svg#robotolight_italic') format('svg');
    font-weight : 300;
    font-style  : italic;
}

@font-face
{
    font-family : 'Roboto';
    src         : url('../fonts/Roboto-Italic-webfont.eot');
    src         : url('../fonts/Roboto-Italic-webfont.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Roboto-Italic-webfont.woff') format('woff'),
    url('../fonts/Roboto-Italic-webfont.ttf') format('truetype'),
    url('../fonts/Roboto-Italic-webfont.svg#robotoitalic') format('svg');
    font-weight : normal;
    font-style  : italic;
}

@font-face
{
    font-family : 'Roboto';
    src         : url('../fonts/Roboto-Bold-webfont.eot');
    src         : url('../fonts/Roboto-Bold-webfont.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Roboto-Bold-webfont.woff') format('woff'),
    url('../fonts/Roboto-Bold-webfont.ttf') format('truetype'),
    url('../fonts/Roboto-Bold-webfont.svg#robotobold') format('svg');
    font-weight : bold;
    font-style  : normal;
}

@font-face
{
    font-family : 'Roboto';
    src         : url('../fonts/Roboto-Medium-webfont.eot');
    src         : url('../fonts/Roboto-Medium-webfont.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Roboto-Medium-webfont.woff') format('woff'),
    url('../fonts/Roboto-Medium-webfont.ttf') format('truetype'),
    url('../fonts/Roboto-Medium-webfont.svg#robotomedium') format('svg');
    font-weight : 500;
    font-style  : normal;
}
